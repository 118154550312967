<template>
   <div>
      <Slider />
      <BlogHolder :posts="postData" :pagination="pagination" @currentPage="setPage" />
   </div>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Blog',
    mixins: [coreMixin],
    data () {
       return {
            page: 'blog',
            posts: null,
            currentPage: 1,
       };
    },
    components: {
      Slider: () => import('./slider'),
      BlogHolder: () => import('./blogHolder'),
    },
   computed: {
        postData () {
            return this.posts !== null ? this.posts.data : null;
        },
        pagination () {
            let data = null;
            if (this.posts !== null) {
                 data = {
                    total: this.posts.total,
                    perPage: this.posts.perPage,
                    page: this.posts.page,
                    lastPage: this.posts.lastPage,
                };
            }
            return data;

        },

    },
    watch: {
        async currentPage(currentPage) {
             this.posts = await this.retrieveActivePosts(currentPage);

        },
    },
     async mounted() {
        this.posts = await this.retrieveActivePosts(this.currentPage);
    },
    methods: {
        setPage(currentPage) {
            this.currentPage = currentPage;

        }
    }

}
</script>

<style>

</style>